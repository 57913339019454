import React from 'react';

import Index, { IndexProps } from 'components/Layout';
import CookieNotice from 'components/Banners/CookieNotice';
import BouyguesSuccess from 'components/BouyguesSuccess';
import SEO from 'components/seo';

const BouyguesSuccessPage: React.FC<IndexProps> = ({ pageContext }) => (
  <>
    <Index pageContext={pageContext}>
      <main>
        <SEO pageTitleTermPath="bytelSuccess" />
        <BouyguesSuccess />
      </main>
      <CookieNotice />
    </Index>
  </>
);

export default BouyguesSuccessPage;
