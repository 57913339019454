import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Links from 'helpers/constants/links';
import { isMobileOS, isTablet } from 'helpers/utils/mobile';

import styles from './CookieNotice.module.scss';

const CookieNotice: React.FC = () => {
  const [agreed, setAgreed] = useState(true);
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    setMobile(isMobileOS() && !isTablet());
    const alreadyAgreed = window.localStorage.getItem('cookies_agreed');
    if (!alreadyAgreed) setAgreed(false);
  }, []);

  const closeWidth = isMobile ? { width: '100vw' } : {};
  return !agreed ? (
    <div className={styles.container}>
      <div className={styles.content}>
        <FormattedMessage
          id="components.cookies.note"
          values={{
            a: (...chunks: string[]): JSX.Element => (
              <a
                href={Links.LEGAL.COOKIES_POLICY}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                {chunks}
              </a>
            ),
          }}
        />
      </div>
      <span
        aria-label="Close"
        role="button"
        tabIndex={0}
        className={styles.close}
        onClick={(): void => {
          window.localStorage.setItem('cookies_agreed', 'true');
          setAgreed(true);
        }}
        onKeyPress={(): void => {
          window.localStorage.setItem('cookies_agreed', 'true');
          setAgreed(true);
        }}
        style={closeWidth}
      >
        <div className={styles.closeButton}>
          <FormattedMessage id="components.cookies.button" />
        </div>
      </span>
    </div>
  ) : null;
};

export default CookieNotice;
