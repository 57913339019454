/* eslint-disable max-len,no-shadow */
import React from 'react';
import phone from 'images/bouygues/phone_@2x.png';

import bouyguesLogo from '../../images/bouygues/bouygues_logo_@2x.png';
import onoffLogo from '../../images/bouygues/onoff-logo.svg';
import qrCode from '../../images/bouygues/qr_@2x.png';

import styles from './BouyguesSuccess.module.scss';

const BouyguesSuccess: React.FC = () => (
  <div className={styles.container}>
    <div className={styles.wrapper}>
      <div className={styles.bouyguesPromoImg}>
        <img src={phone} alt="Big phone" className={styles.img} />
      </div>

      <div className={styles.content}>
        <div className={styles.logos}>
          <img src={bouyguesLogo} className={styles.logo} alt="Bouygues logo" />
          <img src={onoffLogo} className={styles.logo} alt="Onoff logo" />
        </div>

        <div className={styles.qrCode}>

          <div className={styles.textContainer}>
            <div>Merci pour votre inscription, pour pouvoir profiter de votre second numéro, vous devez maintenant installer l’application sur votre téléphone mobile.</div>
            <br />
            <div>Nous vous avons envoyé un email Onoff avec le lien de téléchargement.</div>
            <br />
            <div>Vous pouvez également flasher ce QR code directement depuis votre téléphone.</div>
          </div>

          <img src={qrCode} alt="QR code" className={styles.img} />
          <div className={styles.text}>Bonne journée et à bientôt avec Onoff </div>
        </div>
      </div>
    </div>
  </div>
);

export default BouyguesSuccess;
